<template>
  <div class="me_page">
    <v-header :params="{ title, leftIcon: false }"></v-header>
    <div class="content">
      <div class="box">
        <div class="user_bar" v-if="loginInfo.access_token" @click="goPage('userInfo')">
          <van-image class="photo" round fit="cover" :src="userInfo.avatarUrl" />
          <div class="userInfo">
            <div class="user">
              <p>{{userInfo.nickName || ''}}</p>
              <p>{{userInfo.vipLevel ? userInfo.vipLevel + '级VIP': '普通'}}用户</p>
            </div>
            <van-icon name="qr" color="#999999" size="40" />
            <van-icon name="arrow" color="#999999" size="20" />
          </div>
        </div>
        <div class="user_bar" v-else @click="goPage('login')">
          <van-image class="photo" round fit="cover" src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fedpic%2F4f%2Fed%2F09%2F4fed09319ef5b25b18ef412169ddd2fc.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652862343&t=8f9e33f52dbab45ebcf2341356e0bea8" />
          <div class="userInfo">
            登录/注册
            <van-icon name="arrow" color="#999999" size="20" />
          </div>
        </div>
        <van-grid :border="false">
          <van-grid-item>
            <span class="grid-item-span">帖子 <br>{{userInfo.myTotalForumPost || 0}}</span>
          </van-grid-item>
          <van-grid-item>
            <span class="grid-item-span">关注 <br>{{userInfo.myAttention || 0}}</span>
          </van-grid-item>
          <van-grid-item>
            <span class="grid-item-span">粉丝 <br>{{userInfo.myFans || 0}}</span>
          </van-grid-item>
          <van-grid-item>
            <span class="grid-item-span">消息 <br>
              <van-icon name="envelop-o" />
            </span>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="box">
        <p class="min_title">我的</p>
        <van-grid square>
          <van-grid-item icon="point-gift-o" text="查看积分" @click="goPage('integral')" />
          <!-- <van-grid-item icon="gold-coin-o" text="资产" @click="developing" />
          <van-grid-item icon="diamond-o" text="等级" @click="developing" />
          <van-grid-item icon="warn-o" text="拉黑" @click="developing" /> -->
          <van-grid-item icon="star-o" text="收藏" @click="goPage('collect')" />
          <van-grid-item icon="manager-o" text="大师包月" @click="goPage('master')" />
          <van-grid-item icon="vip-card-o" text="开通会员" @click="becomesvip" />

        </van-grid>
      </div>
      <div class="box">
        <p class="min_title">系统</p>
        <van-grid square>
          <van-grid-item icon="comment-o" @click="developing" text="反馈" />
          <van-grid-item icon="share-o" text="分享" @click="developing" />
          <van-grid-item icon="friends-o" text="联系" @click="goPage('contact')" />
          <!-- <van-grid-item icon="bar-chart-o" text="版本" @click="version()"/> -->
          <van-grid-item icon="setting-o" text="设置" @click="goPage('setUp')" />
        </van-grid>
      </div>
      <p style="margin: 25px 0px 0px 0px;text-align: center;color: blue;font-size: 15px;" @click="toUserAgreement">《好劲抽用户协议》</p>
      <p style="margin: 5px;text-align: center;color: blue;font-size: 15px;" @click="toPrivacyAgreement">《好劲抽隐私协议》</p>
      <!-- <span style="color:red; font-size: 14px; margin: 10px;" @click="goPage('login')">去登录（测试用）</span> -->
    </div>
    <v-footer></v-footer>
    <!-- 分享底部栏 -->
    <!-- <div v-if="share" class="share_bottom">
      <p>选择要分享到的平台</p>
      <div class="share_images">
          <div class="share">
            <div class="share_item">
              <img src="../../assets/images/weixin.png" >
            </div>
            <span>微信</span>
          </div>
          <div class="share">
            <div class="share_item">
              <img src="../../assets/images/friendCircle.png" >
            </div>
            <span>微信朋友圈</span>
          </div> 
          <div class="share">
            <div class="share_item">
              <img src="../../assets/images/qq.png" >
            </div>
            <span>QQ</span>
          </div>
      </div>
      <span class="dot"></span>
      <div class="cancle">取消分享</div> -->
    <!-- </div> -->
    <!-- 出现版本号 -->
      <!-- <p :hidden="!showVersion" class="version">已是最新版本</p> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vFooter from "@/components/footer.vue";
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
  components: {
    vHeader,
    vFooter,
  },
  computed: {
    ...mapState({
      loginInfo: state => state.common.loginInfo,
      userInfo: state => state.common.userInfo
    })
  },
  data() {
    return {
      title: this.$route.meta.title,
      //share:false
      showVersion : false,
      showHeader:true
    };
  },
  created() {
    this.onLoad()
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
  },
  methods: {
    async onLoad() {
      if (this.loginInfo.access_token && this.loginInfo.userId) {
        const res = await this.$HTTP.get(this, this.$API['API_USER_ANON_DETAIL'] + '/' + this.loginInfo.userId, {}, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
        this.$store.commit('common/updateState', [{ name: ['userInfo'], value: res.data || {} }])
      }
    },
    version(){
      this.showVersion = true
      //let p = document.getElementsByClassName('version')[0]
      setTimeout(() => {
        this.showVersion = false
      }, 800);
    },
    // shareTo(){
    //   this.share = true
    // },
    developing(){
      this.$toast('开发中...');
    },
    becomesvip(){
        this.$router.push({
                            name:'vippayment',
                            query:{
                                userid:this.$store.state.common.loginInfo.userId,
                                access_token:this.$store.state.common.loginInfo.access_token,
                                
                            }
        })

    },
    toUserAgreement(){
      this.$router.push({name:'userAgreement'});
    },
    toPrivacyAgreement(){
      this.$router.push({name:'privacyAgreement'});
    }
  }
};
</script>

<style lang="less" scoped>
.me_page {
  .content {
    margin-bottom: 60px;
    .box {
      margin-top: 10px;
      background-color: white;
      .min_title {
        font-size: 13px;
        margin: 0;
        padding: 6px 12px;
        opacity: 0.5;
      }
      .grid-item-span {
        font-size: 14px;
        text-align: center;
      }
      .user_bar {
        padding: 5%;
        display: flex;
        align-items: center;
        .photo {
          width: 70px;
          height: 70px;
        }
        .userInfo {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          margin-left: 12px;
          font-size: 20px;
          .user {
            p {
              margin: 0;
              font-size: 16px;
              line-height: 1.5em;
            }
          }
        }
      }
    }
  }
  .version{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    font-size: 18px;
    /* transition: all linear 0.8s; */
  }
  /* .animate{
    position: absolute;
    top: 50%;
    left: 50%;
  } */
  /* .share_bottom{
    position: fixed;
    z-index: 9999;
    bottom: 0;
    width: 100%;
    height: 160px;
    background-color: #eee;
    font-size: 16px;
    p{
      text-align: center;
    }
    .share_images{
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 12px;
      .share{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;  
        .share_item{
          width: 40px;
          height: 40px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    .dot{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgb(74, 88, 171);
      position: absolute;
      left: 50%;
      bottom: 34px;
      transform: translateX(-50%);
    }
    .cancle{
      width: 100%;
      height: 32px;
      line-height: 32px;
      background-color: #fff;
      text-align: center;
      position: fixed;
      bottom: 0;
    }
  } */
}
</style>
